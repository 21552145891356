:root {
  --white: white;
  --black: black;
  background-color: #1c1d1d;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.hero {
  background-color: #1c1d1d;
  background-image: url('../images/background_overlay_black.png'), url('../images/demo_background.png');
  background-position: 0 0, 50% 0;
  background-repeat: repeat, no-repeat;
  background-size: cover, cover;
  height: 1200px;
  min-height: 1200px;
}

.main-nav-bar {
  background-color: rgba(87, 87, 87, 0);
  width: 100%;
  height: 100px;
  position: absolute;
  top: 20px;
}

.main-nav-bar-scrolled {
  z-index: 1000;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(28, 29, 29, 50%);
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  /*transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;*/
}

.container {
  background-color: rgba(144, 126, 126, 0);
  width: 100%;
  max-width: 1260px;
  height: 100%;
}

.container-2 {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1260px;
  height: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
}

.hero-columns {
  width: 100%;
  height: 80%;
  margin-left: 0;
}

.hero-left-column {
  height: 100%;
}

.hero-right-column {
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  display: flex;
  position: relative;
}

.hero-booking-holder {
  background-color: #f8f8f8;
  border-radius: 20px;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  min-width: 380px;
  height: 714px;
  display: flex;
}

.hero-left-holder {
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 60px;
  display: flex;
}

.main-heading {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-family: Bayon, sans-serif;
  font-size: 117px;
  font-weight: 400;
  line-height: 83.5%;
  text-align: left;
}

.main-paragraph {
  color: #fff;
  text-transform: uppercase;
  font-family: Anektamil Variablefont Wdth Wght, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
  text-align: left;
}

.text-span {
  color: #b8ff8c;
}

.check-services-link {
  color: #fff;
  text-transform: uppercase;
  font-family: Anektamil Variablefont Wdth Wght, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.div-block-4 {
  background-image: url('../images/background_overlay_black.png');
  background-position: 0 0;
  background-size: cover;
  width: 100%;
  height: 90vh;
  position: absolute;
}

.nav-holder {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
}

.nav-logo {
  background-image: url('../images/parlordore_green.png');
  background-position: 0 0;
  background-size: contain;
  width: 200px;
  height: 37px;
}

.main-nav-link {
  color: #fff;
  text-transform: uppercase;
  padding-left: 35px;
  font-family: Anektamil Variablefont Wdth Wght, sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
}

.tabs, .select-service {
  width: 100%;
  height: 100%;
}

.booking-inner-holder {
  flex-flow: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  overflow: hidden;
}

.div-block-51 {
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

.booking-back {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.image-3 {
  width: 100%;
  height: 100%;
}

.booking-title-label-back {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 19px;
  font-weight: 700;
}

.select-service-grid {
  grid-row-gap: 16px;
  grid-template-rows: 216px 216px 216px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
}

.individual-service-holder {
  /*background-image: url('../images/demo_item_1.png');*/
  background-position: 0 0;
  background-size: cover;
  border-radius: 13px;
  width: 100%;
  height: 216px;
  overflow: scroll;
}

.inner-service-holder {
  background-image: url('../images/item_overlay.png');
  background-position: 0 0;
  background-size: cover;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: static;
}

.booking-title-label {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font-family: Satoshi Variable, sans-serif;
  font-size: 19px;
  font-weight: 700;
}

.tabs-menu {
  display: none;
}

.select-service-type-holder {
  background-color: rgba(255, 255, 255, .36);
  border-radius: 40px;
  margin-top: 9px;
  margin-left: 7px;
}

.select-service-type-label {
  color: #fff;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.individual-service-bottom-holder {
  background-color: rgba(207, 155, 155, 0);
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 17px;
  padding-left: 11px;
  padding-right: 7px;
  display: flex;
}

.select-service-title-label {
  color: #fff;
  text-align: left;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 121%;
}

.select-service-provider-label {
  color: #fff;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 121%;
}

.width-container {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1260px;
  display: flex;
}

.services-title {
  background-color: #1c1d1d;
  width: 100%;
  height: auto;
}

.div-block-11 {
  width: 100%;
}

.tabs-content {
  height: 100%;
  overflow: hidden;
}

.choose-addon-background {
  background-color: #eee;
  border-radius: 13px;
  flex: none;
  height: 85%;
  margin-bottom: 20px;
  padding-top: 19px;
  padding-left: 19px;
  padding-right: 19px;
  display: flex;
  overflow: scroll;
}

.secondary-heading {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Bayon, sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 117%;
  text-align: left;
}

.services-title-column-right {
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  display: flex;
}

.services-title-colums {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 43px 60px 54px;
  display: flex;
}

.text-block-6 {
  color: #fff;
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.our-services-book-now-button {
  border: 1px solid #fff;
  border-radius: 41px;
  padding: 20px 40px;
  text-decoration: none;
}

.inner-services-column-holder {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.services {
  background-color: #000;
}

.services-holder {
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  height: 516px;
  display: flex;
  overflow: scroll;
}

.services-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: none;
  grid-template: ". Area" 100%
  / 360px 360px 360px 360px 360px 360px;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.service_item_2 {
  background-image: url('../images/demo_service_2.png');
  background-position: 0 0;
  background-size: auto;
}

.service-item-holder {
  background-image: url('../images/service_item_overlay.png');
  background-position: 0 0;
  background-size: cover;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 360px;
  height: 100%;
  margin-bottom: 0;
  padding: 17px 15px 36px;
  display: flex;
}

.service-item-category-label {
  color: #fff;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.service-item-category-holder {
  justify-content: center;
  align-items: center;
  display: block;
}

.service-item-category {
  background-color: rgba(255, 255, 255, .24);
  border: 1px solid #fff;
  margin-top: 0;
  margin-left: 0;
  padding: 5px 10px;
}

.service-item-bottom-holder {
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.service-item-provider-label {
  color: #fff;
  width: 100%;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.service-item-title-label {
  color: #fff;
  margin-bottom: 8px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 27px;
  font-weight: 700;
  line-height: 121%;
  text-align: left;
}

.service-item-price-holder {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.service-item-price {
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  font-size: 17px;
  font-weight: 900;
}

.service_item {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 360px;
  height: 100%;
}

.div-block-21 {
  background-color: #eee;
  width: 100%;
  height: 100%;
}

.choose-addon {
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
}

.booking-info-holder {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #fff;
  flex-flow: column;
  justify-content: space-between;
  height: auto;
  padding: 19px 19px 30px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 3px 20px 1px rgba(0, 0, 0, .2);
  border-radius: 0px 0px 13px 13px;
}

.button {
  text-align: center;
  background-color: #043c2d;
  border-radius: 20px;
  height: 40px;
  font-family: Satoshi Variable, sans-serif;
}

.booking-info-provider-image {
  background-color: #e0e0e0;
  border-radius: 6px;
  width: 34px;
  height: 34px;
  margin-right: 11px;
}

.booking-info-inner-holder {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.div-block-25 {
  flex-flow: column;
  display: flex;
}

.booking-info-provider-label {
  font-family: Satoshi Variable, sans-serif;
  font-weight: 700;
  line-height: 122%;
}

.booking-info-provider-description-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  text-align: left;
}

.booking-info-inner-left {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.booking-info-price-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.booking-info-title-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 122%;
  text-align: left;
}

.choose-addon-grid {
  grid-template-rows: 108px 108px;
  width: 100%;
  height: 100%;
}

.addon_card {
  border: 1px solid #d7d7d7;
  border-radius: 13px;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 14px;
  padding-left: 12px;
  display: flex;
}

.addon_card_selected {
  border: 1px solid #d7d7d7;
  border-radius: 13px;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 14px;
  padding-left: 12px;
  display: flex;
  background-color: #032226;
}

.text-block-15 {
  color: #000;
  margin-bottom: 6px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.text-block-15-selected {
  color: #ffffff;
  margin-bottom: 6px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.text-block-16 {
  color: #979797;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.text-block-16-selected {
  color: #ffffff;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.div-block-28 {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.div-block-29 {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 11px;
  padding: 5px 15px;
}

.text-block-17 {
  font-family: Satoshi Variable, sans-serif;
  font-weight: 600;
}

.select_service_holder {
  background-color: #eee;
  border-radius: 13px;
  flex: none;
  width: 100%;
  height: 85%;
  margin-bottom: 20px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  overflow: scroll;
}

.footer {
  background-color: #1c1d1d;
  border-bottom: 8px solid #b8ff8c;
  height: auto;
  padding-top: 95px;
  padding-bottom: 83px;
}

.container-4 {
  width: 100%;
  max-width: 1260px;
}

.footer-logo {
  background-image: url('../images/parlordore_green.png');
  background-position: 0 0;
  background-size: contain;
  width: 160px;
  height: 30px;
  margin-bottom: 16px;
}

.footer-holder {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.get-notified-label {
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
}

.text-field {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, .24);
  border-radius: 24px;
  width: 360px;
  height: 48px;
  margin-bottom: 0;
}

.form {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 72px;
  display: flex;
}

.submit-button {
  color: #000;
  background-color: #b8ff8c;
  border-radius: 24px;
  width: 112px;
  height: 48px;
  font-family: Satoshi Variable, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.div-block-31 {
  background-image: url('../images/title_arrow.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
}

.select-service-price-label {
  clear: none;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.select-service-price-holder {
  background-color: #fff;
  border-radius: 40px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 22px;
  margin-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.choose-timeslot {
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
}

.choose-time-background {
  background-color: #eee;
  border-radius: 13px;
  flex-flow: column;
  flex: none;
  height: 85%;
  margin-bottom: 20px;
  padding-top: 16px;
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  overflow: scroll;
}

.div-block-32 {
  background-color: rgba(255, 255, 255, 0);
  border: 1px #000;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  display: flex;
}

.div-block-33 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.text-block-18 {
  color: #000;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.div-block-34 {
  border: 1px solid #979797;
  border-radius: 32px;
  padding: 5px 15px;
}

.text-block-19 {
  color: #979797;
  font-family: Satoshi Variable, sans-serif;
}

.choose-time-day-holder {
  text-align: center;
  background-color: #d7d7d7;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-bottom: 15px;
  display: flex;
}

.choose-time-day-holder-selected {
  text-align: center;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-bottom: 15px;
  display: flex;
  outline-offset: -4px;
  color: #fff;
  background-color: #043c2d;
  outline: 1px solid #fff;
}

.div-block-36 {
  justify-content: left;
  margin-bottom: 28px;
  display: flex;
  overflow: scroll;
  gap: 8px;
}

.choose-time-day-label {
  color: #979797;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.choose-time-date-holder {
  color: #979797;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.dow-label {
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  color: #979797;
}

.dow-label-selected {
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  color: #000;
}

.div-block-37 {
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

.grid {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.timeslot-holder {
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.timeslot-month-holder {
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.timeslot-holder-selected {
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  background-color: #043c2d;
}

.timeslot-month-holder-selected {
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  background-color: #043c2d;
}

.timeslot-icon {
  background-image: url('../images/timeslot_sun.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.timeslot-time-label {
  color: #979797;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 500;
}

.timeslot-time-label-selected {
  color: #ffffff;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 500;
}

.choose-provider {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.choose-provider-holder {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 13px;
  flex: 1;
  width: 100%;
  height: 85%;
  margin-bottom: 20px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  overflow: scroll;
}

.choose-provider-grid {
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
}

.individual-provider-holder {
  border: 1px solid #d7d7d7;
  border-radius: 13px;
  width: 100%;
  height: 216px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
}

.individual-provider-holder-selected {
  border: 1px solid #d7d7d7;
  border-radius: 13px;
  width: 100%;
  height: 216px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
  background-color: #043c2d;
}


.inner-provider-holder {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 23px 0;
  display: flex;
  position: static;
}

.choose-provider-profile-image {
  /*background-image: url('../images/provider_item_1.png');*/
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  width: 63px;
  height: 63px;
}

.individual-provider-bottom-holder {
  background-color: rgba(207, 155, 155, 0);
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 17px;
  padding-left: 11px;
  padding-right: 7px;
  display: flex;
}

.select-provider-title-label {
  color: #000;
  text-align: center;
  width: 100%;
  height: auto;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 121%;
}

.select-provider-title-label-selected {
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: auto;
  font-family: Satoshi Variable, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 121%;
}

.select-provider-desc-label {
  color: #575757;
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 121%;
}

.select-provider-desc-label-selected {
  color: #ffffff;
  text-align: center;
  font-family: Satoshi Variable, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 121%;
}

.randon-provider-icon {
  background-image: url('../images/randon_provider.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
  width: 63px;
  height: 63px;
}

.confirmation {
  width: 100%;
  height: 100%;
}

.div-block-38 {
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
}

.div-block-39 {
  background-image: url('../images/appointment_success.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
  margin-right: 14px;
}

.booking-title-success-label {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, sans-serif;
  font-size: 19px;
  font-weight: 700;
  text-align: left;
}

.appointment-confirmed-background {
  background-color: #eee;
  border-radius: 13px;
  flex-flow: column;
  flex: none;
  height: auto;
  margin-bottom: 12px;
  padding: 21px 21px 23px;
  display: flex;
  overflow: scroll;
}

.image {
  border-radius: 5px;
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.appointment-confirmation-provider-holder {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
  display: flex;
}

.div-block-41 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.appointment-confirmation-provider-name {
  font-family: Satoshi Variable, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
}

.appointment-confirmation-provider-position {
  color: gray;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 500;
  text-align: left;
}

.appointment-confirmation-entry-holder {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.appointment-confirmation-entry-title {
  color: #7b7b7b;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 500;
  text-align: left;
}

.appointment-confirmation-entry-value-label {
  color: #8b8b8b;
  text-align: right;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 500;
}

.appointment-confirmation-entries {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  flex-flow: column;
  width: 100%;
  margin-bottom: 21px;
  display: flex;
}

.appointment-confirmation-amount-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-align: left;
}

.appointment-confirmation-add-to-calendar-holder {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  margin-bottom: 23px;
  display: flex;
}

.appointment-confirmation-calendar-title {
  color: #7b7b7b;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 700;
  text-align: left;
}

.appointment-confirmation-calendar-grid {
  grid-template-rows: 38px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.calendar-option-item {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.appointment-confirmation-bottom-total-label {
  color: #000;
  font-family: Satoshi Variable, sans-serif;
  font-weight: 700;
  text-align: left;
}

.tax-included-label {
  color: #7e7e7e;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  text-align: left;
}

.div-block-46 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: column;
  display: flex;
}

.appointment-confirmation-cost-holder {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.appointment-confirmed-background-map {
  background-color: #eee;
  border-radius: 13px;
  flex-flow: row;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin-bottom: 20px;
  padding: 15px 21px;
  display: flex;
  overflow: scroll;
}

.image-2 {
  border-radius: 11px;
  margin-right: 14px;
}

.address-name-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}

.address-label {
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  text-align: left;
}

.appointment-confirmation-address-holder {
  flex-flow: column;
  display: flex;
}

.div-block-49 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.get-directions-link {
  color: #4d4d4d;
  font-family: Satoshi Variable, sans-serif;
  font-size: 12px;
  text-align: left;
}

.appointment-confirmed-button {
  text-align: center;
  background-color: #043c2d;
  border-radius: 20px;
  height: 40px;
  margin-bottom: 12px;
  font-family: Satoshi Variable, sans-serif;
}

.appointment-confirmed-signin-button {
  color: #000;
  text-align: center;
  background-color: rgba(4, 60, 45, .34);
  border-radius: 20px;
  height: 40px;
  margin-bottom: 12px;
  font-family: Satoshi Variable, sans-serif;
}

@media screen and (max-width: 991px) {
  .hero {
    background-image: url('../images/background_overlay_black.png'), url('../images/demo_background.png');
    background-position: 0 0, 50%;
    background-repeat: repeat, no-repeat;
    background-size: cover, cover;
    height: auto;
    padding-bottom: 60px;
  }

  .container {
    width: 100%;
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-2 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-columns {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    margin-top: 220px;
    display: flex;
  }

  .hero-left-column {
    display: flex;
  }

  .hero-right-column {
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-top: 40px;
  }

  .hero-booking-holder {
    width: 388px;
  }

  .hero-left-holder {
    justify-content: flex-end;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-heading {
    text-align: center;
  }

  .main-paragraph {
    text-align: center;
    margin-bottom: 40px;
  }

  .check-services-link {
    text-align: center;
  }

  .div-block-4 {
    float: none;
    clear: left;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  .nav-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-logo {
    background-position: 0%;
    background-repeat: no-repeat;
  }

  .width-container {
    min-width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }

  .secondary-heading {
    text-align: center;
  }

  .services-title-colums {
    flex-flow: column;
  }

  .inner-services-column-holder {
    justify-content: center;
    align-items: center;
  }

  .container-4 {
    min-width: 100%;
  }

  .div-block-30 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container, .container-2, .hero-left-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-heading {
    font-size: 99px;
  }

  .nav-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-nav-link {
    padding-left: 0;
  }

  .get-notified-label {
    font-size: 26px;
  }

  .nav-items-holder {
    grid-column-gap: 13px;
    grid-row-gap: 13px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 479px) {
  .container, .container-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-columns {
    margin-top: 140px;
  }

  .hero-left-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-heading {
    font-size: 67px;
  }

  .main-paragraph {
    width: 100%;
    margin-bottom: 20px;
  }

  .nav-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-logo {
    width: 150px;
  }

  .main-nav-link {
    padding-left: 0;
    font-size: 16px;
  }

  .width-container, .services-title-colums {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .container-4 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-logo {
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .get-notified-label {
    font-size: 22px;
  }

  .text-field {
    text-align: center;
    width: 100%;
    font-family: Satoshi Variable, sans-serif;
    font-size: 16px;
  }

  .form {
    flex-flow: column;
    width: 100%;
    margin-bottom: 40px;
  }

  .submit-button, .form-block {
    width: 100%;
  }

  .nav-items-holder {
    grid-column-gap: 11px;
    grid-row-gap: 11px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

#w-node-_8bef66c0-2dc2-0756-eaa5-83165c507513-03cc0abe, #w-node-_2e39b010-7533-5ca2-69bf-9b5b4a92f039-03cc0abe, #w-node-_0f17b14a-af9c-99f8-cdaa-301916669970-03cc0abe {
  align-self: center;
  justify-self: center;
}

#w-node-f6be6bb6-d919-ef78-af12-e568b38fea57-03cc0abe, #w-node-f7d9d194-98a9-3884-a321-a50203f5009f-03cc0abe, #w-node-a7c1a1d8-6c4e-1396-85e8-595ad9bafcc4-03cc0abe, #w-node-_57bc174a-15fa-93b9-5336-e28254306b0c-03cc0abe {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_522e420a-42e6-b5db-841a-5a8ea5b3e257-03cc0abe {
  grid-area: Area;
}


@font-face {
  font-family: 'Bayon';
  src: url('../fonts/Bayon-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Anektamil Variablefont Wdth Wght';
  src: url('../fonts/AnekTamil-VariableFont_wdthwght.ttf') format('truetype');
  font-weight: 100 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi Variable';
  src: url('../fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-style: normal;
  font-display: swap;
}
